<template>
  <v-card  class="pa-4 ma-3" style="z-index:100">
    <v-col class="pa-0">
      <v-row class="pa-0">
        <v-text-field
          v-model="input.availableResources"
          label="Number of desks/resources"
          type="number"
          min="1"
          hide-details="auto"
        ></v-text-field>
      </v-row>

      <v-row class="mt-6" justify="start" align="start">
          <date-picker label="Select a date" v-model="input.fromDateVal"></date-picker>

          <v-text-field
            v-model="input.numDays"
            hide-details="auto"
            min="1"
            max="30"
            label="Days to schedule"
            type="number"
            style="width: 280px"
          >
            <template v-slot:message="{ message }">
              <span v-html="message"></span>
            </template>
          </v-text-field>
      </v-row>

      <v-row class="pa-0" justify="start" align="start">
          <v-text-field
            outlined
            @keypress.enter="addName"
            v-model="input.nameInput"          
            background-color="#e5f7fc"      
            label="Enter an employee name"
            :hint="input.nameInput != '' ? 'Press ENTER to confirm' : 'e.g. &quot;John Doe&quot;'"
            style="width: 280px"
          ></v-text-field>
      </v-row>

      <v-row>
        <v-btn @click="addName" :disabled="input.nameInput == ''" color="primary">Add</v-btn>
      </v-row>
    </v-col>
    <!--
    <div class="input-box slider">
      <span>Standard working time:</span>
      <span>From</span> 
      <div class="select-wrapper">
        <v-select v-model="workingTimeRange[0]" hide-details solo :items="hourLabels"
          item-text="state"
          item-value="abbr" menu-props="auto"></v-select>
      </div>

      <span>to</span>

      <div class="select-wrapper">
        <v-select v-model="workingTimeRange[1]" hide-details solo :items="hourLabels"
          item-text="state"
          item-value="abbr" menu-props="auto"></v-select>
      </div>
    </div>
    -->
  </v-card>
</template>

<script>
import DatePicker from './DatePicker.vue';

export default {
    props: {
        input: Object
    },

    components: {
        DatePicker
    },

    data() {
        return {

        }
    },

    methods: {
        addName() {
            this.$emit('name-added');
        }
    }
}
</script>