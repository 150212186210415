<template>
  <div class="app-root">
    <portal-target name="pselector-portal"></portal-target>

    <v-row v-if="canEdit">
      <settings-box :input="input" @name-added="addName" />
      <share-box :dirty="dirty" :share-data="shareData" :can-edit="canEdit" @save-local="saveLocal" @share="share" @export="exportData" />
    </v-row>

    <v-card class="ma-0 pa-2" v-else-if="!shareData.error">
      <v-col>
        <v-row>
          <p class="ma-0 text-body-2">You are viewing a shared schedule. To request changes, please consult your administrator who created the schedule. If you are the administrator, please re-open the schedule with your personal administrator link.
            You can also create a new schedule by clicking here:
          </p>
        </v-row>

        <v-row class="mt-6">
          <v-btn to="/" color="primary">Create new schedule</v-btn>
        </v-row>
      </v-col>
    </v-card>

    <v-card class="ma-0 pa-2" v-else>
      <v-col>
        <v-row>
          <p class="ma-0 text-body-2" style="color:#f00">
            This schedule link is invalid. Please check the URL for typos. 
          </p>
        </v-row>

        <v-row>
          <p class="ma-0 text-body-2">
            Note: If you stored your schedule locally in your browser, make sure you are using the same browser you used to create it. Also, "Incognito Mode" won't work in this case.
          </p>
        </v-row>

        <v-row class="mt-6">
          <v-btn to="/" color="primary">Create new schedule</v-btn>
        </v-row>
      </v-col>
    </v-card>

    <div class="table-wrapper" v-if="!shareData.error">
      <table class="schedule-table">
        <thead class="header">
          <tr>
            <th :key="header.value" v-for="header in tableHeaders">
              <div class="header-row">
                <a @click="toggleSort(header.value)">
                  <span>{{ header.text }}</span>
                  <v-icon v-if="input.sortBy == header.value">{{ icons['mdiArrow' +  (!input.sortDescending ? 'Down' : 'Up')] }}</v-icon>
                </a>
                
                <v-icon v-if="header.value != 'name' && canEdit" class="ml-1 toggler" @click="toggleAll(header.dKey)" small title="Check/uncheck all">{{ icons.mdiCheckBoxMultipleOutline }}</v-icon>
              </div>
            </th>
          </tr>
        </thead>

        <!-- <template v-slot:no-data>
          No data provided. Please check your settings in the box above.
        </template> -->
        <tbody>
          <tr v-if="!input.names.length">
            <td :colspan="input.numDays + 1">
              <div class="empty" v-if="canEdit">Please enter your employees' names above to create your schedule.</div>
              <div class="empty" v-else>No data has been entered.</div>
            </td>
          </tr>
          <tr v-for="i in tableItems" :key="i.id">
            <td :class="{'critical': Math.max(...presentSum[header.dKey]) > input.availableResources}" :key="header.value" v-for="header in tableHeaders" :style="{'width': header.value == 'name' ? '200px' : 'auto'}">            
              <template v-if="header.value == 'name'">
                <div
                    v-if="editedItem == i.id">
                  <v-text-field
                    :ref="'nameTextField-' + i.id"
                    v-if="editedItem == i.id"
                    @keypress.enter="editName"
                    v-model="editedName"                
                    label="Change name"
                    :hint="editedName != '' ? 'Press ENTER to confirm' : 'e.g. &quot;John Doe&quot;'"
                    style="width: 280px"
                  ></v-text-field>
                  <v-icon 
                    @click="editName"
                    label="Accept"
                    class="mr-2">
                    {{ icons.mdiCheckCircle }}
                  </v-icon>
                </div>
                <span class="name-value" v-else>{{ i.name }}</span>

                <v-icon
                    v-if="editedItem != i.id && canEdit"
                    small
                    class="mr-2"
                    @click="editItem(i.id)"
                >
                    {{ icons.mdiPencil }}
                </v-icon>

                <v-icon
                    v-if="editedItem != i.id && canEdit"
                    small
                    @click="deleteItem(i.id)"
                >
                    {{ icons.mdiDelete }}
                </v-icon>
              </template>

              <presence-selector :disabled="!canEdit" v-else :index="input.presenceData[i.id + '_' + header.dKey] || 0" @changed="changePresence(i.id, header.dKey, $event)" />
            </td>
          </tr>
        </tbody>


        <tfoot>
          <tr>
            <th :key="header.value" v-for="header in tableHeaders">
              <div v-if="header.value != 'name'">
                <span class="sum" :class="{'bad': (presentSum[header.dKey] || [0])[0] > input.availableResources}">
                  <small>AM</small>
                  {{ presentSum[header.dKey] != null ? presentSum[header.dKey][0] : 0 }}
                </span>
              
                <span class="sum" :class="{'bad': (presentSum[header.dKey] || [1])[0] > input.availableResources}">
                  <small>PM</small>
                  {{ presentSum[header.dKey] != null ? presentSum[header.dKey][1] : 0 }}
                </span>
              </div>

              <div v-else><span style="font-size:14px;">Total resources used:</span></div>
            </th>
          </tr>
        </tfoot>
        
      </table>
    </div>

    <v-dialog
      v-model="exportDialog"
      persistent
      max-width="290"
      >
      <v-card>
          <v-card-title class="headline">
          Export
          </v-card-title>
          <v-card-text>
              <v-row class="mt-4" align="center" justify="center">
                  <p>Your export is ready.</p>

                  <v-btn color="primary" :href="'/file/' + shareData.fileId" class="mt-3" target="_blank">
                      <v-icon>{{ icons.mdiDownload }}</v-icon>
                      Download file</v-btn>
              </v-row>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  @click="dismissExportDialog"
              >
                  Close
              </v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>

    <footer>
      ⓒ 2020-2021 ResPlanner.io <router-link to="/terms">Terms</router-link> | <router-link to="/privacy">Privacy</router-link> | <router-link to="/contact">Contact us</router-link>
    </footer>
  </div>
</template>

<style lang="scss">
  footer {
    margin-top:20px;
  }
  .text-body-2 {
    font-weight:300;
  }
  span.name-value {
    display:inline-block;
    margin-right:15px;
  }

  .table-wrapper {
    margin-top:40px;
    height:80vh;
    width:100%;
    box-sizing:border-box;
    overflow:auto;
    position: relative;
  }

  .schedule-table {
    border: 0px;
    padding:0; 
    margin:0;
    box-sizing:border-box;
    border-collapse: collapse;
    width:100%;

    tr, td {
      border:none;

      &.critical {
        background:#fcdedc;
      }
    }

    tbody {
      tr {
        height:20px;

        &:hover td {
          background:#efefef;
        }

        td {
          div {
            display:flex;
            flex-direction: row;
            justify-content: center;
          }

          &:first-child {
            div {
              justify-content: flex-start;
            }
          }

          .v-input--selection-controls__input {
            margin:0;
            position: relative;
            top:11px;
          }
        }
      }

      .empty {
        padding:5px;
      }
    }

    thead.header th {
      position: sticky;
      font-weight:500;
      z-index:10;
      top:0;
      background: #fff;
      text-align: center;
      min-width:80px;
      height:30px;
      border-bottom:1px solid #ccc;

      .toggler {
      }

      &:hover {
        .toggler {
          visibility: visible;
        }
      }

      a {
        color:#098bc9;
        user-select: none;
      }

      &:first-child {
        text-align: left;
        width:350px;
        min-width:200px;
      }
    }

    tfoot tr {
      height:60px;

      th {
        border-top:1px solid #ccc;
        position: sticky;
        font-weight:500;
        bottom:0;
        background:#fff;

        div {
          display:flex;
          flex-direction: row;
          justify-content: center;
        }
      }
    }
  }
</style>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import { uuid } from 'vue-uuid'; 
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import SettingsBox from './SettingsBox.vue';
import ShareBox from './ShareBox.vue';
import { mdiArrowDown, mdiArrowUp, mdiCheckBoxMultipleOutline, mdiDelete, mdiCheckCircle, mdiPencil } from '@mdi/js'
import PresenceSelector from './PresenceSelector.vue';

dayjs.extend(LocalizedFormat);

export default {
  components: { SettingsBox, ShareBox, PresenceSelector },
  name: 'ResPlanner.io',
  props: {
    msg: String
  },

  created() {
    if(this.$route.params.id) {
      if(this.$route.params.id != 'local') {
        axios.get('/api/schedule/' + this.$route.params.id + (this.$route.params.editId ? '/' + this.$route.params.editId : ''))
        .then(r => {
          this.loadFromData(r.data);
        })
        .catch(() => {
          this.shareData.error = true;
        });
      } else if(this.$route.params.editId) {
        let object = window.localStorage.getItem(this.$route.params.editId);

        if(!object) {
          this.shareData.error = true;
          this.shareData.canEdit = false;
          return;
        }

        this.shareData.saved = true;
        this.loadFromData({object: {content: JSON.parse(object)}, shareInfo: {source: 'localStorage'}});
      } else {
        this.shareData.error = true;
        this.shareData.canEdit = false;
      }
      
    }   

    window.onbeforeunload = () => (this.dirty ? 'Are you sure you want to leave? Unsaved data will be lost.' : null);
  },

  data() {
    return {
      dirty: false,
      exportDialog: false,

      icons: {
        mdiArrowDown, mdiArrowUp, mdiCheckBoxMultipleOutline, mdiDelete, mdiCheckCircle, mdiPencil
      },

      shareData: {
        fileId: null,
        loading: false,
        editToken: null,
        shared: false,
        saved: false,
        canEdit: null,
        error: false
      },

      input: {
        sortBy: null,
        sortDescending: false,
        availableResources: 10,
        numDays: 5,
        nameInput: '',
        workingTimeRange: [8, 17],     
        fromDateMenu: false,
        fromDateVal: dayjs().format('YYYY-MM-DD'),
        minDate: '2021-01-01',
        idCounter: 0,
        names: [],
        presenceData: {}, 
      },

      editedItem: null,
      editedName: '',
    }
  },

  computed: {
    canEdit() {
      return (this.shareData.canEdit ?? true) && !!this.$route.params.editId || !this.$route.params.id;
    },

    fromDateDisp() {      
      return dayjs(this.input.fromDateVal, 'YYYY-MM-DD').format('YYYY-MM-DD');
    },

    tableHeaders() {  
      let out = [ {
        text: 'Name',
        sortable: true,
        align: 'start',
        value: 'name'
      }];

      for(let i = 0; i < this.input.numDays; i++) {
        let d = dayjs(this.input.fromDateVal, 'YYYY-MM-DD').add(i, 'days');

        out.push({
          text: d.format('MMM D'),
          sortable: true,
          dKey: d.format('YYYYMMDD'),
          align: 'center',
          value: 'isPresent_' + d.format('YYYYMMDD')
        });
      }

      return out;
    },

    presentSum() {
      let out = {};

      for(let i = 0; i < this.input.numDays; i++) {
        let d = dayjs(this.input.fromDateVal, 'YYYY-MM-DD').add(i, 'days');
        let dCode = d.format('YYYYMMDD');

        for(let name of this.input.names) {
          if(!out[dCode]) {
            out[dCode] = [0, 0];
          }

          let val = this.input.presenceData[name.id + '_' + dCode] || 0;

          if(val == 2 || val == 1) {
            out[dCode][0]++;
          }

          if(val == 2 || val == 3) {
            out[dCode][1]++;
          }
        }
        
      }

      return out;
    },

    tableItems() {      
      let out = [];

      for(let name of this.input.names) {
        let obj = {'name': name.name, 'id': name.id};

        for(let i = 0; i < this.input.numDays; i++) {
          let d = dayjs(this.input.fromDateVal, 'YYYY-MM-DD').add(i, 'days');
          let dCode = d.format('YYYYMMDD');

          obj['isPresent_' + dCode] = (this.input.presenceData[name.id + '_' + dCode] ?? false) ? 1 : 0;
        }

        out.push(obj);
      }

      if(this.input.sortBy != null) {
        return out.sort((a, b) => {
          if(a[this.input.sortBy] < b[this.input.sortBy]) {
            return this.input.sortDescending ? 1 : -1;
          }

          if(a[this.input.sortBy] > b[this.input.sortBy]) {
            return this.input.sortDescending ? -1 : 1;
          }

          return 0;
        });
      }

      return out;
    },

    hourLabels() {
      let out = [];

      for(let i = 0; i < 24; i++) {
        out.push({abbr: i, state: i < 12 ? ((i == 0 ? '12 AM' : i + ' AM')) : ((i == 12 ? '12' : (i - 12)) + ' PM')});
      }

      return out;
    }
  },

  beforeRouteLeave (to, from , next) {
    const answer = window.confirm('Are you sure you want to leave? Unsaved changes will be lost.');

    if (answer) {
      next();
    } else {
      next(false)
    }
  },

  methods: {
    formatTime(val) {
      return val + ':00';
    },

    exportData(mode) {
      let url = '/export/local/' + mode;
      this.shareData.loading = true;

      axios.post(url, this.toJson())
      .then(r => {
        this.shareData.fileId = r.data.filename;
        if(mode == 'xls') {
          this.exportDialog = true;
        }      
      })
      .catch(() => {

      })
      .finally(() => {
        this.shareData.loading = false;
      });
    },

    toggleSort(col) {
      if(this.input.sortBy == col) {
        if(!this.input.sortDescending) {
          this.input.sortDescending = true;
        } else {
          this.input.sortBy = null;
          this.input.sortDescending = false;
        }
      } else {
        this.input.sortBy = col;
      }
    },

    loadFromData(data) {
      this.$set(this.input, 'availableResources', data.object.content.availableResources);
      this.$set(this.input, 'names', data.object.content.names);
      this.$set(this.input, 'presenceData', data.object.content.presenceData);
      this.$set(this.shareData, 'canEdit', data.shareInfo.canEdit);
      this.input.idCounter = data.object.content.names.length + 1;
      this.shareData.shared = data.shareInfo.source != 'localStorage';
    },

    toggleAll(col) {
      if(!this.input.names.length) {
        return;
      }

      console.log(col);

      let toggle = !this.input.presenceData[this.input.names[0].id + '_' + col];

      for(let name of this.input.names) {
        this.$set(this.input.presenceData, name.id + '_' + col, toggle ? 2 : 0);
      }
    },

    deleteItem(val) {
      this.input.names.splice(this.input.names.findIndex(e => e.id == val), 1);
      this.dirty = true;
    },

    dismissExportDialog() {
      this.exportDialog = false;
      this.shareData.fileId = null;
    },

    async editItem(val) {
      this.editedItem = val;
      this.editedName = this.input.names.find(e => e.id == val).name;

      await this.$nextTick();

      this.$refs['nameTextField-' + val][0].focus();

    },

    editName() {
      this.input.names.find(e => e.id == this.editedItem).name = this.editedName;
      this.editedItem = null;
      this.dirty = true;
    },
    
    changePresence(item, dKey, value) {
      this.$set(this.input.presenceData, item + '_' + dKey, value);
      this.dirty = true;
    },

    toJson() {
      return this.input;
    },

    share() {
      axios.post('/api/schedule' + (this.$route.params.id != 'local' && this.$route.params.editId ? '/' + this.$route.params.id + '/' + this.$route.params.editId : ''), this.toJson())
      .then(r => {
        this.loadFromData(r.data);
        this.$router.replace({
          name: "Home",
          params: {
            editId: r.data.object.editKey,
            id: r.data.object.id,
          }
        });
        this.dirty = false;

      });
    },

    saveLocal() {
      let json = this.toJson();
      let id = !this.$route.params.editId ? uuid.v4() : this.$route.params.editId;

      window.localStorage.setItem(id, JSON.stringify(json));
      this.shareData.saved = true;
      
      if(!this.$route.params.editId) {
        this.$router.replace({
            name: "Home",
            params: {
              editId: id,
              id: 'local',
            }
        });
      }

      this.dirty = false;

    },

    addName() {
      if(!this.input.nameInput.trim().length) {
        return;
      }
      
      this.dirty = true;

      this.input.names.push({id: this.input.idCounter++, name: this.input.nameInput});
      this.input.nameInput = '';
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.v-data-table .v-icon{visibility:hidden}
.v-data-table tr:hover .v-icon {visibility:visible}

  .app-root {
    margin-top:15px;
    display:flex;
    flex-direction: column;

    span.sum {
      color:#274724;
      font-size:14px;
      font-weight:300;
      background:#49e67f;
      padding:2px 5px;
      position: relative;
      margin-top:10px;

      small {
        left:0;
        top:-15px;
        width:100%;
        font-size:8px;
        text-align: center;
        color:#666;
        position: absolute;
      }
    }

    span.sum + span.sum {
      margin-left:5px;
    }

    span.sum.bad {
      color:#591b1b;
      background:#f0a8a5;
    }

    .input-box {
      display:flex;
      align-items: center;
      flex-direction: row;
      padding:5px;

      &.slider {
        position: relative;
        z-index:50;
      }
      
      .number-input {
        width:200px;
      }
    }
  }
</style>
