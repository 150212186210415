<template>
  <v-col justify="start">
    <h1 class="text-h3">ResPlanner.io</h1>
    <h3 class="text-h6">Simple Office Attendance Sheet</h3>
    <p class="text">Use this tool to schedule employee attendance in your office and manage a limited resources such as desks for on-site employees.
      You can share your plan with your employees via a link or store it locally in your browser.</p>
    <PlannerApp />
  </v-col>
</template>

<script>
import 'normalize.css';
import PlannerApp from '../components/PlannerApp.vue'

export default {
  name: 'App',
  components: {
    PlannerApp
  }
}
</script>

<style lang="scss">
@import 'normalize-scss';

body {
  margin:0;
  padding:15px;
}

#app {
  text-align: left;
  font-family:'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  h1 {
    margin:0;
    padding:0;
    font-weight:200;
  }
}
</style>
