<template>
    <v-card class="pa-4 ma-3" width="900px">
        <v-row>
            <v-col cols="12">
                <p class="text-body-2" v-if="!shareData.shared">Save your schedule on our servers. You will receive a link to share with your employees. You can edit your schedule with a personal, secret admin link.</p>
                <template v-else>
                    <span class="text-caption">
                        Use these links to share and edit your schedule. <strong>Only</strong> reveal your administrator link to persons who are entitled to edit your schedule. 
                    </span>

                    <v-text-field class="mt-2" :value="adminLink" readonly label="Administrator link (to edit)" />
                    <v-text-field class="mt-0" :value="shareLink" readonly label="Sharing link (read-only)" />
                </template>

                <v-btn v-if="!shareData.shared" color="blue-grey" class="white--text" small @click="$emit('share')">
                    <v-icon dark>{{ icons.mdiCloudUpload }}</v-icon>
                    <span class="ml-2">Save and Share</span>
                </v-btn>
                
                <template v-if="!shareData.shared && !shareData.saved">
                    <p class="mt-4 text-body-2">Save your schedule locally in your browser. No data is sent to our servers. Only you will be able to edit/see
                    </p>
                    <v-btn small @click="$emit('save-local')">
                        <v-icon>{{ icons.mdiHarddisk }}</v-icon>
                        <span class="ml-2">Save locally</span>
                    </v-btn>
                </template>

                <template v-else-if="!shareData.shared">
                    <p class="mt-4 text-body-2">
                        Your schedule has been saved in your browser. Save the following link to your bookmarks. It can only be viewed with your current browser. You can't share this link.
                    </p>

                    <v-text-field class="mt-2" :value="localLink" readonly label="Schedule URL (only working in this browser!)" />
                </template>

            </v-col>

            <v-col cols="12">
                <p class="text-body-2">Export your schedule as PDF or XLSX spreadsheet. <span v-if="!shareData.shared">Data will be sent to our servers once for processing and will be deleted afterwards.</span></p>
                
                <v-row justify="start" dense>
                    <v-dialog
                        v-model="dialog"
                        persistent
                        max-width="290"
                        >
                        <template v-slot:activator="{ on, attrs }">  
                            <v-btn v-on="on" v-bind="attrs" color="primary" small>
                                <v-icon dark>{{ icons.mdiFilePdfBox }}</v-icon>
                                <span class="ml-2">PDF</span>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="headline">
                            Export as PDF
                            </v-card-title>
                            <v-card-text>
                                <v-col class="mt-4" align="center" justify="center" v-if="!shareData.fileId && !shareData.loading">
                                    <p>Choose an orientation:</p>
                                    <v-btn color="primary" @click="$emit('export', 'pdf-landscape')">
                                        <v-icon>{{ icons.mdiCropLandscape }}</v-icon>
                                        Landscape
                                    </v-btn>
                                    <v-btn color="primary" class="mt-3" @click="$emit('export', 'pdf-portrait')">
                                        <v-icon>{{ icons.mdiCropPortrait }}</v-icon>
                                        Portrait</v-btn>
                                </v-col>

                                <v-row class="mt-4" align="center" justify="center" v-else-if="shareData.loading">
                                    Please wait...
                                </v-row>

                                <v-row class="mt-4" align="center" justify="center" v-else>
                                    <p>Your export is ready.</p>

                                    <v-btn color="primary" :href="'/file/' + shareData.fileId" class="mt-3" target="_blank">
                                        <v-icon>{{ icons.mdiDownload }}</v-icon>
                                        Download file</v-btn>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="dismissExportDialog"
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <span style="display:inline-block; width:15px;"></span>
                    
                    <v-btn color="primary" small @click="$emit('export', 'xls')">
                        <v-icon dark>{{ icons.mdiFileExcel }}</v-icon>
                        <span class="ml-2">Spreadsheet</span>
                    </v-btn>
                </v-row>
                    
                <v-row v-if="dirty && (shareData.shared || shareData.saved)">
                    <v-col>
                        <p class="text-body-2" style="color:#f00">There are unsaved changes.</p>
                        <v-btn color="blue-grey" class="white--text" small @click="$emit(!shareData.shared ? 'save-local' : 'share')">
                            <v-icon dark>{{ icons.mdiCloudUpload }}</v-icon>
                            <span class="ml-2">Save changes</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import { mdiCropPortrait, mdiCropLandscape, mdiCloudUpload, mdiFilePdfBox, mdiDownload, mdiFileExcel, mdiHarddisk } from '@mdi/js';
export default {
    props: {
        input: Object,
        shareData: Object,
        canEdit: Boolean,
        dirty: Boolean
    },

    data() {
        return {
            dialog: false,
            icons: {
                mdiCropPortrait, mdiCropLandscape, mdiCloudUpload, mdiFilePdfBox, mdiDownload, mdiFileExcel, mdiHarddisk
            }
        }
    },

    computed: {
        shareLink() {
            return window.location.origin + '/' + this.$router.resolve({name: 'Home', params: {id: this.$route.params.id}}).href;
        },

        adminLink() {
            return window.location.origin + '/' + this.$router.resolve({name: 'Home', params: this.$route.params }).href;
        },

        localLink() {
            return window.location.origin + '/' + this.$router.resolve({name: 'Home', params: {id: 'local', editId: this.$route.params.editId} }).href;
        },
    },


    methods: {

        dismissExportDialog() {
            this.dialog = false;
            this.shareData.fileId = null;
        },

        addName() {
            this.$emit('name-added');
        }
    }
}
</script>