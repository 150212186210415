<template>
    <div class="pselector" v-click-outside="closePopup" :class="{'disabled': disabled}">
        <v-tooltip open-delay="300" bottom class="item">
            <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs" class="value" ref="button" @click="openPopup" :style="{'background-color': colors[index]}">{{ values[index] }}</span>
            </template>
            
            <span>{{ titles[index] }}</span>
        </v-tooltip>

        <div class="pselector-popup" ref="popup" v-if="popupVisible">
            <v-tooltip bottom v-for="(val, i) in values" :key="i" class="item">
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" @click="changeValue(i)" class="value">
                        {{ val }}
                    </span>
                </template>

                <span>{{ titles[i] }}</span>
            </v-tooltip>
        </div>    
    </div>
</template>

<style lang="scss" scoped>
    .pselector {
        display:flex;
        flex-direction: row;
        justify-content: center;

    }

    .pselector-popup {
        position:absolute;
        z-index:100;
        background:#fff;
        box-shadow:0 0 2px #ccc;
        padding:3px;
    }

    .pselector-popup, .pselector {
        span.value {
            margin:3px 0;
            display:inline-block;
            user-select: none;
            padding:3px;
            width:35px;
            text-align: center;
            cursor:pointer;
            background:#edece9;
            border-radius:4px;

            &:hover {
                box-shadow:0 0 2px #ccc;
                background:#fff;
            }
        }

        &.disabled span.value {
            cursor:default;
        }

        .item {
            & + span.value {
                margin-left:5px;
            }
        }
    }
</style>

<script>
import ClickOutside from 'vue-click-outside'

export default {
    props: {
        index: Number,
        disabled: Boolean
    },

    data() {
        return {
            popupVisible: false,
            values: ['X', 'AM', 'P', 'PM', 'RE'],
            titles: ['All-day absent', 'Present in the morning only', 'All-day present', 'Present in the afternoon only', 'All-day remote-working'],
            colors: ['#edece9', '#dbffe1', '#49e67f', '#dbffe1', '#00bdbd']
        }
    },

    methods: {
        async openPopup() {
            if(this.disabled) return;
            this.popupVisible = true;
            await this.$nextTick();
            //this.adjustPopup();
        },

        closePopup() {
            this.popupVisible = false;
        },
        
        changeValue(i) {
            if(this.disabled) return;

            this.$emit('changed', i);
            this.popupVisible = false;
        }
    },

    directives: {
        ClickOutside
    }
}
</script>